/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./store";
import { CookiesProvider } from "react-cookie";
import ErrorBoundary from "./components/ErrorBoundary";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { generalTheme } from "./assets/base/theme";
import RootRouter from "./rootRouter";
import ThemeProvider from "./ThemeContext/ThemeProvider";
import * as Sentry from "@sentry/react";
import "animate.css";
import "./utils/extendFuncs";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c681a15e603e351d71bed5ffc674df11@o4505087076335616.ingest.us.sentry.io/4507817596747776",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    autoSessionTracking: true,
    release: `prod@1.0.0`, // e.g., 'my-app@1.0.0'
    environment: "prod-v3",
  });
}

function launchApp(swObject) {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <MuiThemeProvider theme={generalTheme}>
              <ThemeProvider>
                <RootRouter swObject={swObject} />
              </ThemeProvider>
            </MuiThemeProvider>
          </CookiesProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>,
    document.getElementById("root")
  );
}

launchApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
